import styled from "styled-components";

export const Header = styled.div`
  position: relative;
  width: 100%;
  height: 100vh !important; /* Full height */
  @media only screen and (min-width: 1024px) {
    height: 64vh;
  }
  overflow: hidden;

  .image-wrapper {
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

export const TextWrapper = styled.div`
  position: absolute;
  bottom: 12%;
  left: 10%;
  transform: translateX(0);
  color: white;
  padding: 1em;
  background: rgba(0, 0, 0, 0.4);
  width: auto;
  min-width: 500px;

  @media only screen and (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    min-width: unset;
    max-width: 500px;
    text-align: center;
  }

  .headDropShadow {
    text-shadow: 4px 4px 5px black;
  }

  .custom-closet {
    background-color: rgba(118, 39, 56, 0.9);
    color: white;
    padding: 1px 3px;
    &:hover, &:focus {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const IconsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
  margin-top: 20px;
  
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
`;

export const IconItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 8px;
    filter: brightness(0) invert(1);

    @media only screen and (max-width: 768px) {
      width: 35px;
      height: 35px;
      margin-bottom: 4px;
    }
  }

  .icon-title {
    font-size: 0.9rem;
    font-family: "Roboto Bold";
    color: white;
    text-shadow: 2px 2px 4px black;
    white-space: pre-wrap;
    line-height: 0.9;

    @media only screen and (max-width: 768px) {
      font-size: 0.7rem;
      line-height: 1;
    }
  }
`;

export const Container = styled.div`
  max-width: 100%;
  margin-left: 5%;
  padding: 55px 30px 55px 30px;

  @media only screen and (min-width: 1200px) {
    min-height: 680px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    min-height: 375px;
    padding: 40px 15px 15px 30px;
    margin: auto;

    button {
      width: 100% !important;
    }
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 6%;
  left: 10%;
  transform: translateX(0);
  width: auto;
  text-align: left;

  @media only screen and (max-width: 768px) {
    bottom: 4%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
  }
`;

export const StyledButton = styled.button`
  background-color: #762738;
  color: white;
  border: none;
  padding: 12px 24px;
  font-family: "Roboto Bold";
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: auto;
  min-width: 200px;

  &:hover {
    background-color: #8f2f44;
  }

  @media only screen and (max-width: 768px) {
    margin: 0 auto;
  }
`;